<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->

      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-ve"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img
                  alt="Vehículo Eléctrico"
                  src="/media/cide/ecosistemas/VE.png"
                />
              </v-avatar>
              <h1 class="texto-ecosistema-ve">
                Vehiculo Eléctrico. {{ this.currentObject.nombre }} -
                {{ this.currentProvince.nombre }}
              </h1>
              <v-spacer />
              <v-select
                v-model="selected"
                :items="this.currentObject.provincias"
                item-text="nombre"
                item-value="slug"
                @change="changeProvincia"
              />

              <v-spacer />

              <router-link
                :to="'/vehiculo-electrico/' + this.$route.params.comunidad"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Volver a {{ this.currentObject.nombre }}
              </router-link>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-6" />
                <div class="col-xl-2">Nacional</div>
                <div class="col-xl-2">
                  {{ this.currentObject.nombre }}
                </div>
                <div class="col-xl-2">
                  {{ this.currentProvince.nombre }}
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].provincia.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].provincia.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Contribución a la descarbonización del Vehículo Eléctrico
                    2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].provincia.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].nacional.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].comunidad.ve }}
                  </span>
                  toneladas CO2 equivalente evitadas/10.000 habitantes
                </div>
              </div>
              <v-divider />
              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2020
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].comunidad.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2020].provincia.nve }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].comunidad.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2021].provincia.nve }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <span class="font-weight-medium titulo"
                    >Nº vehículos eléctricos registrados en zonas rurales 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].nacional.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].comunidad.nve }}
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2022].provincia.nve }}
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>Número de vehículos eléctrcios</v-card-title>

            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="400"
                data-format="jsonurl"
                :data-source="
                  '/data/VE/provincia/' +
                  this.currentSlug +
                  '_tipovehiculo_inp04.json'
                "
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.provincia,

      dataJson: [],
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.provincia;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug == this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
    currentProvince() {
      let selectedProvince = this.currentObject.provincias.filter(
        (provincia) => {
          return provincia.slug == this.$route.params.provincia;
        }
      );
      return selectedProvince[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    changeProvincia(selected) {
      this.$router.push({
        name: "vehiculo-electrico-provincia",
        params: {
          comunidad: this.$route.params.comunidad,
          provincia: selected,
        },
      });
    },
    getJsonData() {
      fetch("/data/VE/provincia/" + this.currentSlug + "_data.json")
        .then((response) => response.json())
        .then(
          (data) => {
            this.dataJson = data;
          },
          () => {
            this.getJsonData();
          }
        );
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
